<template>
  <div id="page-content-wrapper">
    <div
      class="bg-light page-header-wrapper border-bottom"
      style="height: 60px; padding: 0.875rem 1.25rem; font-size: 1.2rem;"
    >
      <div style="float: left;">
        Administration - Integrations
      </div>
    </div>
    <div id="page-content" class="text-left">
      <b-card-group deck>
         <b-card
          header="Integrations"
          style="max-width: 78rem;"
          class="mb-2"
          ref="formContainer5"
        >
          <div slot="header">
            Webex CC Integrations
          </div>
          <b-card-body>
            <b-table
              striped
              hover
              bordered
              small
              :sort-by.sync="sortby"
              :items="allIntegrations"
              :fields="fields"
              class="cy-table1 text-left"
            >
              <template #cell(show_details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                  {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>ClientID:</b></b-col>
                    <b-col>{{ row.item.clientid }}</b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Wekhook Subscription URL:</b></b-col>
                    <b-col>{{ row.item.suburl }}</b-col>
                  </b-row>
                  <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                </b-card>
              </template>
            </b-table>
          </b-card-body>
         </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import ReportService from "@/service/ReportService";

export default {
  name: "IntegrationAdmin",
  data: function() {
    return {
      allIntegrations: [],
      sortBy: 'name',
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Integration Name"
        },
        {
          key: "apiserver",
          sortable: false,
          label: "API Server"
        },
        {
          key: "statsserver",
          sortable: false,
          label: "Stats Server"
        },
        {
          key: "show_details",
          label: "Actions"
        }
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      ReportService.getAllIntegrations(this.$auth.accessToken)
        .then(result => {
          this.allIntegrations = result.data;
        })
    }
  }
}
</script>